import { proxy } from 'valtio'
import _ from 'lodash'

const initialValue = {
  // deposit: 0,
  // withdraw: 0,
  // transfer: 0,
  // verification: 0
}

export const notificationStore = proxy({
  state: initialValue,
  reset() {
    notificationStore.state = { ...initialValue }
  }
})
