import React from 'react'
import {
  SidebarEmailIcon,
  SidebarProfileIcon,
  SidebarScrumBoardIcon,
  SidebarEcommerceIcon,
  SidebarTablesIcon,
  SidebarInvoiceIcon,
  SidebarUIIcon,
  SidebarToDosIcon,
  SidebarAdvancedIcon,
  SidebarFireStoreIcon,
  SidebarMapsIcon,
  SidebarContactsIcon
} from '@iso/config/icon.config'

import { RiHandCoinFill, RiMoneyDollarBoxFill } from 'react-icons/ri'
import { MdAssignmentReturn } from 'react-icons/md'

const options = [
  {
    key: '/dashboard/products',
    label: 'products',
    leftIcon: <SidebarEcommerceIcon size={19} />
  },
  {
    key: '/dashboard/inventories',
    label: 'inventories',
    leftIcon: <SidebarToDosIcon size={19} />
  },
  {
    key: '/dashboard/collections',
    label: 'collections',
    leftIcon: <SidebarAdvancedIcon size={19} />
  },
  {
    key: '/dashboard/categories',
    label: 'categories',
    leftIcon: <SidebarFireStoreIcon size={19} />
  },
  {
    key: '/dashboard/shipping_zones',
    label: 'shippingZones',
    leftIcon: <SidebarMapsIcon size={19} />
  },
  {
    key: '/dashboard/currencies',
    label: 'currencies',
    leftIcon: <RiMoneyDollarBoxFill size={19} />
  },
  {
    key: '/dashboard/orders',
    label: 'orders',
    leftIcon: <SidebarInvoiceIcon size={19} />
  },
  {
    key: '/dashboard/withdraws',
    label: 'withdraws',
    leftIcon: <RiHandCoinFill size={19} />
  },
  {
    key: '/dashboard/refunds',
    label: 'refunds',
    leftIcon: <MdAssignmentReturn size={19} />
  },
  {
    key: '/dashboard/users',
    label: 'users',
    leftIcon: <SidebarProfileIcon size={19} />
  },
  {
    key: '/dashboard/discounts',
    label: 'discounts',
    leftIcon: <SidebarTablesIcon size={19} />
  },
  {
    key: '/dashboard/slides',
    label: 'slides',
    leftIcon: <SidebarUIIcon size={19} />
  },
  {
    key: '/dashboard/notifications',
    label: 'notifications',
    leftIcon: <SidebarEmailIcon size={19} />
  },
  {
    key: '/dashboard/versions',
    label: 'versions',
    leftIcon: <SidebarScrumBoardIcon size={19} />
  },
  {
    key: '/dashboard/admins',
    label: 'admins',
    leftIcon: <SidebarContactsIcon size={19} />,
    access: [0]
  }
]
export default options
