import {
  IoMdMenu,
  IoIosSearch,
  IoIosNotifications,
  IoIosChatbubbles,
  IoMdCart,
  IoMdMail,
  IoMdBasket,
  IoMdMap,
  IoIosPerson,
  IoIosApps,
  IoMdCheckboxOutline,
  IoIosToday,
  IoLogoYoutube,
  IoIosCalendar,
  IoIosPaper,
  IoMdFlame,
  IoMdPersonAdd,
  IoIosKeypad,
  IoMdTrendingUp,
  IoMdListBox,
  IoIosLeaf,
  IoIosFlash,
  IoMdThumbsUp,
  IoMdGrid,
  IoIosCopy,
  IoLogoGithub,
  IoIosDocument,
  IoMdOptions,
  IoIosMailUnread,
  IoMdCamera,
  IoMdChatboxes,
  IoMdCloudDownload,
  IoIosMusicalNotes,
  IoIosPie,
  IoMdTrophy,
  IoMdCloudUpload,
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoGoogleplus,
  IoLogoLinkedin,
  IoLogoDribbble,
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdTrash,
  IoMdFolder,
  IoMdPricetags,
  IoMdArchive,
  IoMdAlert,
  IoMdAdd,
  IoMdClose,
  IoMdMic,
  IoIosSquare,
  IoMdCreate,
} from "react-icons/io"

// TopBar Icons
export const TopbarMenuIcon = IoMdMenu // Menu icon
export const TopbarSearchIcon = IoIosSearch // Search icon
export const TopbarNotifyIcon = IoIosNotifications // Notification icon
export const TopbarMailIcon = IoMdMail // Notification icon
export const TopbarMessageIcon = IoIosChatbubbles // Chat icon
export const TopbarCartIcon = IoMdCart // Cart icon

// SideBar Icons
export const SidebarEmailIcon = IoMdMail // Email icon
export const SidebarChatIcon = IoIosChatbubbles // Chat icon
export const SidebarEcommerceIcon = IoMdBasket // E-commerce icon
export const SidebarMapsIcon = IoMdMap // Map icon
export const SidebarProfileIcon = IoIosPerson // Profile icon
export const SidebarScrumBoardIcon = IoIosApps // Scrum Board icon
export const SidebarInvoiceIcon = IoIosToday // Invoice icon
export const SidebarYouTubeIcon = IoLogoYoutube // YouTube icon
export const SidebarCalendarIcon = IoIosCalendar // Calendar icon
export const SidebarNotesIcon = IoIosPaper // Notes icon
export const SidebarToDosIcon = IoMdCheckboxOutline // ToDos icon
export const SidebarFireStoreIcon = IoMdFlame // FireStore Crud icon
export const SidebarContactsIcon = IoMdPersonAdd // Contacts icon
export const SidebarShuffleIcon = IoIosKeypad // Shuffle icon
export const SidebarChartsIcon = IoMdTrendingUp // Chart icon
export const SidebarFormsIcon = IoMdListBox // Forms icon
export const SidebarUIIcon = IoIosLeaf // UI Elements icon
export const SidebarAdvancedIcon = IoIosFlash // Advanced Elements icon
export const SidebarFeedbackIcon = IoMdThumbsUp // Feedback icon
export const SidebarTablesIcon = IoMdGrid // Tables icon
export const SidebarPagesIcon = IoIosCopy // Pages icon
export const SidebarGithubIcon = IoLogoGithub // Github Search icon
export const SidebarBlankIcon = IoIosDocument // Blank Page icon
export const SidebarMenuLevelIcon = IoMdOptions // Menu levels icon

// Dashboard Widgets Icons
export const StickerWidgetUnreadMailIcon = IoIosMailUnread // Unread email icon
export const StickerWidgetImgUploadIcon = IoMdCamera // Image Upload icon
export const StickerWidgetMessageIcon = IoIosChatbubbles // Total Messages icon
export const StickerWidgetOrderIcon = IoMdCart // Order Post icon

export const CardWidgetNewMsgIcon = IoMdChatboxes // Card New Message icon
export const CardWidgetVolumeIcon = IoIosMusicalNotes // Card Volume icon
export const CardWidgetAchievementIcon = IoMdTrophy // Card Achievement icon

export const ProgressDownloadIcon = IoMdCloudDownload // Card Download icon
export const ProgressPieChartIcon = IoIosPie // Card Pie icon
export const ProgressUploadIcon = IoMdCloudUpload // Card Upload icon

export const SocialFacebookIcon = IoLogoFacebook // Social Facebook icon
export const SocialTwitterIcon = IoLogoTwitter // Social Twitter icon
export const SocialGooglePlusIcon = IoLogoGoogleplus // Social Google Plus icon
export const SocialLinkedinIcon = IoLogoLinkedin // Social Linkedin icon
export const SocialDribbbleIcon = IoLogoDribbble // Social Dribbble icon

// Mail Icons
export const MailDeleteIcon = IoMdTrash // Mail Delete icon
export const MailFolderIcon = IoMdFolder // Mail Move icon
export const MailTagIcon = IoMdPricetags // Mail Tag icon
export const MailArchiveIcon = IoMdArchive // Mail Archive icon
export const MailSpamReportIcon = IoMdAlert // Mail Report icon

// E-commerce Icons
export const VoiceSearchMicIcon = IoMdMic // Voice Search icon
export const VoiceSearchStopIcon = IoIosSquare // Voice Search icon
export const GridViewIcon = IoIosKeypad // Grid View icon
export const ListViewIcon = IoMdMenu // List View icon

// Common Icons
export const ArrowLeftIcon = IoIosArrowBack // Arrow Left icon
export const ArrowRightIcon = IoIosArrowForward // Arrow Right icon
export const AddIcon = IoMdAdd // Add icon
export const CloseIcon = IoMdClose // Close icon
export const CartIcon = IoMdCart // Cart icon
export const DeleteIcon = IoMdTrash // Delete icon
export const EditIcon = IoMdCreate // Edit icon
export const MenuIcon = IoMdMenu // Edit icon
export const FlameIcon = IoMdFlame // Flame icon
