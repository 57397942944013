import { proxy } from 'valtio'
import _ from 'lodash'

const initialValue = {
  token: null,
  profile: null,
  company: null
}

export const authStore = proxy({
  state: initialValue,
  reset() {
    authStore.state = { ...initialValue }
  }
})

export const checkAuthorization = () => {
  const token = getToken()
  const profile = getProfile()
  const company = getCompany()
  if (token != null) {
    login({ token, profile, company })
    return true
  } else {
    console.log('not authenticated')
    return false
  }
}

export const login = ({ token, profile, company }) => {
  if (token) {
    authStore.state.token = token
    authStore.state.profile = profile
    authStore.state.company = company
    console.log(profile)
    console.log(token)
    console.log(company)
    localStorage.setItem('token', token)
    localStorage.setItem('profile', JSON.stringify(profile))
    localStorage.setItem('company', JSON.stringify(company))
  } else {
    // login error
  }
}

export const logout = () => {
  clearToken()
  clearProfile()
  clearCompany()

  authStore.state.token = null
  authStore.state.profile = null
  authStore.state.company = null
}

export function getToken() {
  try {
    const token = localStorage.getItem('token')
    return token
  } catch (err) {
    clearToken()
    return null
  }
}

export function getProfile() {
  try {
    const profile = localStorage.getItem('profile')
    return JSON.parse(profile)
  } catch (err) {
    clearProfile()
    return null
  }
}

export function getCompany() {
  try {
    const company = localStorage.getItem('company')
    return JSON.parse(company)
  } catch (err) {
    clearCompany()
    return null
  }
}

export function clearToken() {
  localStorage.removeItem('token')
}

export function clearProfile() {
  localStorage.removeItem('profile')
}

export function clearCompany() {
  localStorage.removeItem('company')
}
