import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { Space, Spin } from 'antd'

import StatisticsService from '@iso/lib/network/services/statistics'
import { notificationStore } from '@iso/lib/store/notification'
import { global as globalStore } from '@iso/lib/store/global'
import { authStore } from '@iso/lib/store/auth'

import ErrorBoundary from './ErrorBoundary'

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'))
const publicRoutes = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn'))
  },
  {
    path: '/signin',
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn'))
  }
]

function PrivateRoute({ children, ...rest }) {
  const snapshot = useSnapshot(globalStore)
  const {
    state: { token: isLoggedIn }
  } = useSnapshot(authStore)

  const location = useLocation()

  const fetchNotifications = async () => {
    const result = await StatisticsService.getAllPending()
    console.log(result)
    return { notification: result }
  }

  const resetNotification = () => notificationStore.reset()
  const setNotification = (val) => (notificationStore.state = { ...val })

  const refreshNotifications = async () => {
    resetNotification()
    const result = await fetchNotifications()
    // console.log(result)
    setNotification(result.notification)
  }

  useEffect(() => {
    refreshNotifications()
  }, [location])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <Space
            align="center"
            style={{ width: '100vw', height: '100vh', justifyContent: 'center' }}
          >
            <Spin />
          </Space>
        }
      >
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}
